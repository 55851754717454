.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#tsparticles {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    z-index: -1;
}

.App {
    cursor: url(circle.png), auto;
}

.App-header {
    height: 100px;
  }
  
  .name {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
  }
  
  .about {
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    flex-direction: column;
  }



  #about-header {
    position: relative;
  }

  .image-circle {
    width: 25vw; 
    height: 25vw; 
    border-radius: 50%; 
    overflow: hidden; 
    transition: transform 0.3s ease; 
}

.image-circle img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.image-circle:hover {
    transform: scale(1.1); 
}

.about-description {
    font-size: 1.1vw;

}

.grid-container {
    display: flex;
    flex-direction: row;
    width: 75%;
    align-items: center;
}

.grid-item {
    margin: 20px;
}


@media (max-width: 600px) {
    .grid-container {
      flex-direction: column;
    }
  }


/* --------- Skills --------- */


  .skills {
    margin-top: -21px;
    margin-bottom: -22px;
    width: 100%;
    height: 30vh;
    background-color: rgb(73, 71, 71);
  }

  #skills-title {
    color: white;
  }

.carousel {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    width: 75%;
    height: 100px;
    left: 12.5%;
}

.carousel img {
    height: 50px;
    transition: transform 0.3s;
    margin-left: 20px;
}

.carousel img:hover {
    transform: scale(1.2);
}

.moving-logos {
    display: inline-block;
    white-space: nowrap;
    animation: move 40s linear infinite;
}

@keyframes move {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* --------- Projects --------- */

.projects {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.project-cards {
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .project-cards {
      flex-direction: column;
      align-items: center;
    }
  }


/* --------- Contact --------- */

footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
}

.footer-content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form label {
    display: block;
    margin-bottom: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

.contact-form button {
    background-color: #555;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #777;
}

