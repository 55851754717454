
.card {
    height: 420px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    width: 300px; /* or any desired width */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 30px;
}

.card-image {
    width: 100%;
    height: 150px; /* You can adjust this based on your needs */
    object-fit: cover;
}

.card-title {
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
}

.card-description {
    padding: 0 10px 10px 10px;
    font-size: 16px;
    color: #666;
}

.git-icon {
    width: 30px;
    height: 30px;
    margin: 10px;
    transition: transform 0.3s ease;
}

.git-icon:hover {
    transform: scale(1.2); 
}
